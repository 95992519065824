import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const NotFound = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://i.gifer.com/7VE.gif" className="App-logo" alt="logo" />

      </header>
    </div>
  );
}

export default NotFound;